import { useState, useEffect } from "react";
import { Space, Row, Col, Typography } from "antd";
import { FileExcelOutlined, FormOutlined, ToolOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { UserContext } from "../Context";
import eventTrack, { actionNames, categoryNames } from "../../Utils/Tracking/EventTracking";

const { Text } = Typography;

const AppDrawerFormat = styled(Row)`
  & {
    min-width: 400px;
  }
  .anticon {
    font-size: 30px;
    display: inline-block;
  }
  .app {
    text-align: center;
  }
  padding: 20px 0px 16px 0px;
`;

const IconLink = styled(Link)`
  margin: 1rem;
  display: inline-block;
`;

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const AppDrawer = (pr: { control: (a: boolean) => void; DTAUrl: string }) => {
  const role = useContext(UserContext).value?.role || "";
  const isImpersonate = useContext(UserContext).value?.impersonate || "";
  
  return (
    <AppDrawerFormat>
      <Col span={6} className="app">
        <Link to="/custom-query" 
          onClick={() => {
            pr.control(false)
            eventTrack({
              category: categoryNames.header,
              action: actionNames.click,
              label: "Custom Query",
            }); 
          }}>
          <Space direction="vertical" align="center">
            <FileExcelOutlined />
            <Text>Custom Query</Text>
          </Space>
        </Link>
      </Col>
      {pr.DTAUrl !== '' && (
        <Col span={6} className="app">
          <a onClick={() => {
            openInNewTab(pr.DTAUrl)
            pr.control(false)
            eventTrack({
              category: categoryNames.header,
              action: actionNames.click,
              label: "DTA Installer",
            }); 
          }}>
            <Space direction="vertical" align="center" >
              <ToolOutlined/>
              <Text>DTA Installer (VPN Required)</Text>
            </Space>
          </a>
        </Col>
      )}
      {role === "ADMIN" && (
        <Col span={6} className="app">
          <Link to="/alert-cms" 
            onClick={() => {
              pr.control(false)
              eventTrack({
                category: categoryNames.header,
                action: actionNames.click,
                label: "Alert CMS",
              }); 
            }}>
            <Space direction="vertical" align="center">
              <FormOutlined />
              <Text>Alert CMS</Text>
            </Space>
          </Link>
        </Col>
      )}
      {(role === "ADMIN" || isImpersonate === true) && (
        <Col span={6} className="app">
          <Link to="/user-spoofing" 
            onClick={() => {
              pr.control(false)
              eventTrack({
                category: categoryNames.header,
                action: actionNames.click,
                label: "User Spoofing",
              }); 
            }}>
            <Space direction="vertical" align="center">
              <UserSwitchOutlined />
              <Text>User Spoofing</Text>
            </Space>
          </Link>
        </Col>
      )}
    </AppDrawerFormat>
  );
};

export default AppDrawer;
